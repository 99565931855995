<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">

        <b-row>
            <b-col cols="12" xl="8" md="8">
                <b-card no-body>
                    <b-card-body>
                        <b-row>
                            <b-col cols="12" md="4">
                                <b-form-group label="Busqueda Cliente" label-for="search-customer">
                                    <b-input-group class="input-group-merge">
                                        <b-form-input size="sm" id="searc-customer" @click="activeSearchCustomer = true"
                                            readonly v-model="customer.nombre" placeholder="Clic Buscar cliente" autocomplete="off"
                                            @input="changeInputCustomer" @keydown.prevent />
                                        <b-input-group-append>
                                            <b-button size="sm" @click="activeSearchCustomer = true"
                                                variant="outline-primary">
                                                <feather-icon icon="SearchIcon" />
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="4">
                                <b-form-group label="Limite Anterior" label-for="current-limit">
                                    <b-form-input size="sm" readonly id="current-limit" autocomplete="off"
                                        :value="`${formatCurrency(customer.limite_Credito)}`"
                                        placeholder="Limite ActuaL" />
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="4">
                                <b-form-group label="Incremento" label-for="new-limit">
                                    <b-form-input ref="newLimit" size="sm" id="new-limit" autocomplete="off"
                                        v-model="customer.limite_Nuevo" placeholder="0.00" />
                                </b-form-group>
                            </b-col>

                            <!-- Comments -->
                            <b-col cols="12" md="8">
                                <b-form-group label="Comentarios" label-for="comments">
                                    <b-form-textarea v-model="customer.comentarios" autocomplete="off"
                                        placeholder="Escribe un breve comentario"></b-form-textarea>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="4">
                                <b-button size="sm"
                                    v-if="customer.limite_Nuevo > 0 && customer.cliente_Id && isControlTotal"
                                    :disabled="loading" @click="storeCreditLimit" variant="primary" class="mt-2">
                                    Actualizar
                                </b-button>

                                <b-button size="sm" v-if="customer.cliente_Id" :disabled="loading"
                                    @click="resetCustomer" variant="outline-danger" class="mt-2 ml-1">
                                    Cancelar
                                </b-button>
                            </b-col>
                        </b-row>

                        <!-- Histories -->
                        <b-row>
                            <b-col cols="12">
                                <table class="table table-responsive small table-small text-small table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single w-100">
                                    <thead>
                                        <tr>
                                            <th colspan="7" class="text-center">Límites de Créditos Por Revertir</th>
                                        </tr>
                                        <tr>
                                            <th>Cliente</th>
                                            <th>Límite Anterior</th>
                                            <th>Incremento</th>
                                            <th>Actualización</th>
                                            <th>Revertir</th>
                                            <th>Comentarios</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="creditLimits">
                                        <tr v-for="(item, index) in creditLimits" :key="index">
                                            <td>{{ item.cliente_Nombre }}</td>
                                            <td>{{ formatCurrency(item.limite_Actual) }}</td>
                                            <td>{{ formatCurrency(item.limite_Nuevo) }}</td>
                                            <td>{{ formatDate(item.fecha_Actualizacion) }}</td>
                                            <td class="text-capitalize">{{ item.fecha_Revertir }}</td>
                                            <td class="text-wrap">{{ item.comentarios }}</td>
                                            <td>
                                                <b-button @click="revertCreditLimit(item)" size="sm" variant="outline-primary">
                                                    <feather-icon icon="RotateCcwIcon" />
                                                    Revertir
                                                </b-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col cols="12" md="4" xl="4" class="invoice-actions">
              <card-info-customer :info="customer" />
            </b-col>

        </b-row>

        <!-- Search Customers -->
        <search-customers :active="activeSearchCustomer" @selected="selectCustomer"
            @close="activeSearchCustomer = false" />
    </b-overlay>
</template>

<script>
import router from "@/router";
import store from "@/store";
import BCardCode from "@core/components/b-card-code";
import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot,
    BCardText,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BAvatar,
    BFormTextarea,
    BButton,
    BFormInvalidFeedback,
    VBToggle,
    BOverlay,
    BSpinner,
} from "bootstrap-vue";
import moment from 'moment'
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, email, url } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import SearchCustomers from "@core/components/customers/SearchCustomers.vue"
import CardInfoCustomer from "@/@core/components/customers/CardInfoCustomer.vue";

import CustomersProvider from "@/providers/Customers"
const CustomersResource = new CustomersProvider()

export default {
    name: "CreditLimits",
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot,
        BCardText,
        BButton,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormCheckbox,
        BFormTextarea,
        BAvatar,
        vSelect,
        flatPickr,
        ValidationProvider,
        BFormInvalidFeedback,
        ValidationObserver,
        BOverlay,
        BSpinner,
        BCardCode,

        //
        SearchCustomers,
        CardInfoCustomer,
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    data() {
        return {
            loading: false,
            activeSearchCustomer: false,
            customer: {
                nombre: null,
                limite_Nuevo: 0,
                comentarios: null,
            },
            creditLimits: []
        }
    },
    computed: {
        branch() {
            return store.getters['auth/getBranch']
        },
        isControlTotal() {
            return store.getters['auth/getConexion']?.control_Total || false;
        },
    },
    async mounted() {
        await this.getCreditLimits()
    },
    methods: {
        async findCustomer(id) {
            try {

                this.loading = true
                const { data } = await CustomersResource.find(id)
                this.loading = false

                if (data.isSuccesful) {                    
                    this.customer = {
                        ...data.data,
                        comentarios: null,
                    }

                } else {
                    this.danger(data.message)
                }

            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        revertCreditLimit(item) {
            this.$swal.fire({
                title: `¿Desea continuar?`,
                text: `Se ejecutará un proceso para revertir el cambio en los límites de crédito.`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        this.loading = true
                        const { data } = await CustomersResource.revertCreditLimit(0, item.id)
                        this.loading = false
                        if (data.isSuccesful) {
                            this.success(data.message)
                            await this.getCreditLimits()

                            console.log(this.customer)
                            if (this.customer.cliente_Id) {
                                await this.findCustomer(this.customer.cliente_Id)
                            }

                            
                            
                        } else {
                            this.danger(data.success)
                        }
                    }catch(e) {
                        this.loading = false
                        this.handleResponseErrors(e)
                    }
                },
                allowOutsideClick: () => !Swal.isLoading(),
            })
        },
        changeInputCustomer() {

        },
        async selectCustomer(e) {
            this.customer = { ...e }
            this.$nextTick(() => {
                this.$refs['newLimit'].focus()
            })
        },
        async getCreditLimits() {

            this.creditLimits = []

            const query = {
                isRevert: true,
            }

            this.loading = true
            const { data } = await CustomersResource.getCreditLimit(query)
            this.loading = false
            if (data.isSuccesful) {
                this.creditLimits = data.data.map(item => ({...item, fecha_Revertir: this.dateHuman(item.fecha_Actualizacion, 12)}))
            }
        },
        async storeCreditLimit() {

            if (!this.customer.comentarios) {
                this.danger(`Por favor, proporciona un breve comentario para continuar con la operación.`)
                return false

            }

            const payload = {
                id: 0,
                usuario_Id: "string",
                empresa_Id: 0,
                sucursal_Id: this.branch.sucursal_Id,
                cliente_Id: this.customer.cliente_Id,
                limite_Actual: this.customer.limite_Credito,
                limite_Nuevo: parseFloat(this.customer.limite_Credito) + parseFloat(this.customer.limite_Nuevo),
                fecha_Alta: null,
                fecha_Actualizacion: null,
                comentarios: this.customer.comentarios,
            }

            try {

                this.loading = true
                const { data } = await CustomersResource.storeCreditLimit(this.customer.cliente_Id, payload)
                this.loading = false

                if (data.isSuccesful) {
                    this.success(data.message)
                    await this.getCreditLimits();
                    this.customer = {
                        nombre: null,
                        limite_Nuevo: 0,
                        comentarios: null,
                    }

                } else {
                    this.danger(data.message)
                }

            } catch (e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        resetCustomer() {
            this.customer = {
                nombre: null,
                limite_Nuevo: 0,
                comentarios: null,
            }
        }
    }
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/vue/libs/vue-flatpicker.scss";
</style>