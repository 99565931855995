<template>
    <div>
        <!-- Modal Search Customer -->
        <b-modal id="modal-search-customer" ref="modal-search-customer" title="Búsqueda de Clientes" @hidden="close"
            ok-only ok-title="Aceptar" size="lg" no-close-on-backdrop>

            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="12">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-input-group>
                                <b-form-input size="sm" autofocus v-model="searchQuery" class="d-inline-block _mr-1"
                                    placeholder="Buscar por nombre" @keyup.enter="searchCustomers" autocomplete="off" />
                                <b-input-group-prepend>
                                    <b-button size="sm" variant="primary" @click="searchCustomers">
                                        <feather-icon icon="SearchIcon" />
                                    </b-button>
                                </b-input-group-prepend>
                            </b-input-group>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <!-- Table -->
            <b-table ref="selectableTable" class="table b-table table-sm text-small small b-table-no-border-collapse b-table-selectable b-table-select-single" 
                :items="customers" responsive small hover
                :fields="columns" primary-key="id" 
                show-empty empty-text="No se encontraron registros coincidentes" :busy.sync="loading"
                @row-clicked="toggleDetails"
                selectable
                select-mode="single"
                @row-selected="rowSelected">

                <!-- Empty -->
                <template slot="empty">
                    <div v-if="loading" class="d-flex justify-content-center mb-1">
                        <b-spinner style="width: 3rem; height: 3rem" class="mr-1" label="Loading..." variant="primary" />
                    </div>
                </template>

                <!-- Column: name -->
                <template #cell(name)="data">
                    <div @click="chooseCustomer(data.item)">
                        {{ data.item.nombre }}
                    </div>
                </template>

                <template #cell(limite_Credito)="data">
                    <div @click="chooseCustomer(data.item)">
                        {{ formatCurrency(data.item.limite_Credito) }}
                    </div>
                </template>

                <template #cell(rfc_Curp)="data">
                    <div @click="chooseCustomer(data.item)">
                        {{ data.item.rfc_Curp }}
                    </div>
                </template>

                <template #cell(telefono1)="data">
                    <div @click="chooseCustomer(data.item)">
                        {{ data.item.telefono1 }}
                    </div>
                </template>


                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <div class="text-nowrap">
                        <b-button @click="chooseCustomer(data.item)" size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary">
                            Elegir
                        </b-button>
                    </div>
                </template>
            </b-table>
        </b-modal>
    </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar,
} from 'bootstrap-vue'
import router from "@/router";
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'

import CustomersProvider from "@/providers/Customers"
const CustomersResource = new CustomersProvider()

export default {
    name: 'SearchCustomers',
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardBody,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BCardCode,
        BAvatar,
    },
    mixins: [heightTransition],
    props: ["active"],
    data() {
        return {
            loading: false,
            customers: [],
            columns: [
                { key: "actions", label: ' ' },
                {
                    key: 'clave_Cliente',
                    label: 'Clave Cliente',
                    tdClass: 'text-small',
                },
                {
                    key: "nombre",
                    label: 'Nombre',
                    tdClass: 'text-small',
                },
                {
                    key: "limite_Credito",
                    label: "Límite de Crédito",
                    tdClass: 'text-small',
                },
                {
                    key: "rfc_Curp",
                    label: 'RFC - CURP',
                    tdClass: 'text-small',
                },
                {
                    key: "telefono1",
                    label: 'Teléfono',
                    tdClass: 'text-small',
                },
            ],
            table: null,
            searchQuery: null,
            selectedRow: null,
            refCustomerListTable: null,
            selectableTable: null,
        }
    },
    mounted() {
       
    },
    methods: {
        rowSelected(items) {
            
        },
        toggleDetails(item) {
            this.$emit("selected", { ...item });
            this.close();
        },
        onRowClicked(row) {
            this.selectedRow = row
        },
        chooseCustomer(item) {
            this.$emit("selected", { ...item });
            this.close();
        },
        selectFirstRow(){
            this.$refs.selectableTable.selectRow(0)
        },
        async searchCustomers() {

            if (!this.searchQuery) return this.danger(`Debe ingresar el nombre del cliente a buscar`)

            const query = {
                searchText: this.searchQuery,
                pageSize: 10,
                currentPage: 1,
            }

            try {
                this.loading = true
                const { data } = await CustomersResource.getAll(query)
                this.loading = false
                if (data.isSuccesful) {
                    this.customers = data.data

                    this.$nextTick(() => {
                        // this.refCustomerListTable.selectRow(0);
                        this.$refs.selectableTable.selectRow(2)
                    })
                }

                
            }catch(e) {
                this.loading = false
            }
            
        },
        close() {
            this.$refs['modal-search-customer'].hide()
            this.$emit("close");
        },
    },
    watch: {
        active(value) {
            if (value) {
                this.customers = []
                this.searchQuery = null
                this.$refs['modal-search-customer'].toggle()
            } else {
                this.close()
            }
        }
    }
}
</script>