<template>
    <div>
        <b-card v-if="info != undefined" class="border-primary">
            <table class="mt-2 text-small mt-xl-0 w-100">
                <tr>
                    <th class="pb-50">
                        <span class="font-weight-bold d-none d-sm-inline">Dirección</span>
                    </th>
                    <td colspan="3" class="pb-50">
                        <small>{{ info.calle }} {{ info.num_Exterior }} {{ info.colonia }}</small>
                    </td>
                </tr>
                <tr>
                    <th>
                        <span class="font-weight-bold d-none d-sm-inline">RFC</span>
                    </th>
                    <td>
                        {{ info.rfc_Curp }}
                    </td>

                    <th>
                        <span class="font-weight-bold d-none d-sm-inline">Clave Cliente</span>
                    </th>
                    <td>
                        {{ info.clave_Cliente }}
                    </td>
                </tr>

                <tr>
                    <th>
                        <span class="font-weight-bold d-none d-sm-inline">Limite</span>
                    </th>
                    <td>
                        {{ formatCurrency(info.limite_Credito) }}
                    </td>

                    <th>
                        <span class="font-weight-bold d-none d-sm-inline">Teléfono</span>
                    </th>
                    <td>
                        {{ info.telefono1 }}
                    </td>
                </tr>
                <tr>
                    <th>
                        <span class="font-weight-bold d-none d-sm-inline">Condiciones</span>
                    </th>
                    <td>
                        {{ info.plazo }}
                    </td>

                    <th>
                        <span class="font-weight-bold d-none d-sm-inline">Vendedor</span>
                    </th>
                    <td>
                        {{ info.vendedor }}
                    </td>
                </tr>
                <tr>
                    <th>
                        <span class="font-weight-bold d-none d-sm-inline">Tipo</span>
                    </th>
                    <td>
                        {{ info.tipo }}
                    </td>

                    <th>
                        <span class="font-weight-bold d-none d-sm-inline">Cobrador</span>
                    </th>
                    <td>
                        {{ info.cobrador }}
                    </td>
                </tr>
                <tr>
                    <th>
                        <span class="font-weight-bold d-none d-sm-inline">Estatus</span>
                    </th>
                    <td>
                        {{ info.estatus }}
                    </td>

                    <th>
                        <span class="font-weight-bold d-none d-sm-inline">Saldo Total</span>
                    </th>
                    <td>
                        {{ formatCurrency(info.saldo_Cliente) }}
                    </td>
                </tr>
            </table>
        </b-card>
    </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar,
} from 'bootstrap-vue'
import router from "@/router";
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from 'vee-validate/dist/rules';

export default {
    name: 'CardInfoCustomer',
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardBody,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BCardCode,
        BAvatar,
    },
    mixins: [heightTransition],
    props: {
        info: {
            type: Object,
            default: () => {},
            required: true,
        }
    },
    setup() {

    }

}
</script>